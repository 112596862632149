import React from 'react'

import HeroLogos from '../../images/hero-section-logos.png'

import { HeroSectionContainer, HeroSectionAbsolute, HeroDark, HeroSectionWrapper, Section1, Heading, Button, Section2, Col1, Col2, Col3, LineSVG, Logos } from './HeroSectionElements'


import LineSvgPath from '../../images/line.svg'
import { useStateContext } from '../../context/StateContext'


const HeroSection = () => {

    const {menu, setMenu} = useStateContext();

    return (

        <HeroSectionContainer>
            <HeroDark>
                <HeroSectionWrapper>
                    <HeroSectionAbsolute>

                        <Section1>

                            <Col1>
                                <LineSVG src={LineSvgPath} />
                            </Col1>
                            <Col2>
                                <Logos src={HeroLogos} />
                            </Col2>
                            <Col3>
                                <LineSVG src={LineSvgPath} />
                            </Col3>
                        </Section1>

                        <Section2>
                            <Heading>
                                Jesteśmy jedynym i wyłącznym dystrybutorem w Polsce
                                oryginalnego argentyńskiego przysmaku Dulce de leche.
                            </Heading>

                            <Button to='/kontakt'>Zapraszamy do kontaktu</Button>
                        </Section2>

                    </HeroSectionAbsolute>

                </HeroSectionWrapper>
            </HeroDark>
            <div className='receipt-info-container' onClick={() => setMenu(true)} style={{
                cursor: 'pointer',
            }}>
                <p><b>Sprawdź nową recepture
                    dla lodów "Słony Karmel"</b></p>
            </div>
        </HeroSectionContainer>

    )
}

export default HeroSection