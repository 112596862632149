import React from 'react'
import { Column1, Column2, Column3, Column4, FooterAuthorContainer, FooterAuthorWrapper, FooterButtonContact, FooterContainer, FooterHeader, FooterMenu, FooterMenuLinks, FooterParagraph, FooterParagraphAuthor, FooterParagraphAuthorLink, FooterWrapper } from './FooterElements'


const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterWrapper>

                    <Column1>
                        <FooterHeader>
                            Kontakt
                        </FooterHeader>

                        <FooterMenu>
                            <FooterParagraph>
                                Roland Sewruk
                            </FooterParagraph>
                            <FooterParagraph>
                                +48 600 807 702
                            </FooterParagraph>
                            <FooterParagraph>
                                biuro@dulcedeleche.pl
                            </FooterParagraph>
                        </FooterMenu>


                    </Column1>
                    <Column2>
                        <FooterHeader>
                            Biuro
                        </FooterHeader>

                        <FooterMenu>
                            <FooterParagraph>
                                Katarzyna Wiśniewska
                            </FooterParagraph>
                            <FooterParagraph>
                                +48 692 823 849
                            </FooterParagraph>
                            <FooterParagraph>
                                wisniewska@huso.pl
                            </FooterParagraph>
                        </FooterMenu>

                    </Column2>
                    <Column3>
                        <FooterHeader>
                            Zamówienia
                        </FooterHeader>

                        <FooterMenu>
                            <FooterParagraph>
                                roland@huso.pl
                            </FooterParagraph>
                            <FooterButtonContact to='/kontakt'>
                               Kontakt
                            </FooterButtonContact>

                        </FooterMenu>

                    </Column3>
                    <Column4>
                        <FooterMenu>
                            <FooterHeader>
                                Menu
                            </FooterHeader>
                            <FooterParagraph>
                                <FooterMenuLinks to='/'>Strona główna</FooterMenuLinks>
                            </FooterParagraph>

                            {/* <FooterParagraph>
                                <FooterMenuLinks to='/przemysl'>Przemysł</FooterMenuLinks>
                            </FooterParagraph> */}

                            <FooterParagraph>
                                <FooterMenuLinks to='/detal'>Detal</FooterMenuLinks>
                            </FooterParagraph>

                            <FooterParagraph>
                                <FooterMenuLinks to='/lodziarnie'>Lodziarnie</FooterMenuLinks>
                            </FooterParagraph>

                            <FooterParagraph>
                                <FooterMenuLinks to='/cukiernie'>Cukiernie</FooterMenuLinks>
                            </FooterParagraph>

                        </FooterMenu>

                    </Column4>


                </FooterWrapper>

                <FooterAuthorContainer>
                    <FooterAuthorWrapper>
                        <Column1>
                            <FooterParagraphAuthorLink href='https://fesru.pl'>
                                @seewruuk
                            </FooterParagraphAuthorLink>
                        </Column1>

                        <Column2>
                            <FooterParagraphAuthor>
                                Copyright © 2023 dulchedeleche.pl
                            </FooterParagraphAuthor>
                        </Column2>

                    </FooterAuthorWrapper>
                </FooterAuthorContainer>
            </FooterContainer>

        </>
    )
}

export default Footer